@font-face {
  font-family: 'Untitled Sans';
  src: url('/fonts/untitled-sans/untitled-sans-light.woff2') format('woff2');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Untitled Sans';
  src: url('/fonts/untitled-sans/untitled-sans-light-italic.woff2')
    format('woff2');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Untitled Sans';
  src: url('/fonts/untitled-sans/untitled-sans-regular.woff2');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Untitled Sans';
  src: url('/fonts/untitled-sans/untitled-sans-regular-italic.woff2')
    format('woff2');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Untitled Sans';
  src: url('/fonts/untitled-sans/untitled-sans-medium.woff2') format('woff2');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Untitled Sans';
  src: url('/fonts/untitled-sans/untitled-sans-bold.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Untitled Sans';
  src: url('/fonts/untitled-sans/untitled-sans-black.woff2') format('woff2');
  font-style: normal;
  font-weight: 900;
}
